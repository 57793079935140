const onSuccessRegistration = {
  initialize: function (queryParam, reccPeopleUrl){
    this.queryParam = queryParam;
    this.reccPeopleUrl = reccPeopleUrl;
    this.rewriteUrl();
  },
  rewriteUrl: function (){
    const self = this;
    let url = window.location.href;
    if (url.indexOf(self.queryParam) != -1){
      $('.recommended_people_wrap').hide();
      // remove reccResearcher from query string
      url = url.replace(self.queryParam + '=true', '');
      if (url.slice(-1) == '?'){
        url = url.replace('?', '');
      }

      history.pushState({}, null, url);

      // display recommended people modal
      ajaxPopupDialog(self.reccPeopleUrl, $('#ajaxModalWithJs'));
    }
  }
};
